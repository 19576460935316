import React from 'react';
import { useState, useEffect } from 'react';
import { config } from "../Const.js";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Autocomplete, TextField, TableSortLabel, Button, Dialog, Typography } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { styled } from '@mui/material/styles';
import { generateImageUrlFromCDN, executeFetch } from '../Util.js'


function QuizManager(props) {

    const handleLoadQuizClick = props.onLoadQuiz;

    const [quizList, setQuizList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [order, setOrder] = useState('desc');
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('modifyDate');
    const [deleteWarningPopupOpen, setDeleteWarningPopupOpen] = useState(false);
    const [selectedQuizForDelete, setSelectedQuizForDelete] = useState(null);


    const ORDER_ASC = 'asc';
    const ORDER_DESC = 'desc';
    const ORDER_BY_LABEL = 'label';
    const ORDER_BY_MODIFY_DATE = 'modifyDate';
    const ORDER_BY_USER_COUNT = 'users';


    const handleDeleteQuizClick = (quiz) => {

        setDeleteWarningPopupOpen(true);
        setSelectedQuizForDelete(quiz);

    }

    const closeDeleteWarningModal = () => {
        setDeleteWarningPopupOpen(false);
    }

    const applyFiltersSort = () => {

        let quizListClone = JSON.parse(JSON.stringify(quizList));
        filterList.forEach(f => (quizListClone = quizListClone.filter(f)));
        let comparator;


        switch (orderBy) {
            case ORDER_BY_LABEL:
                comparator = labelComparator;
                break;

            case ORDER_BY_MODIFY_DATE:
                comparator = dateComparator;
                break;

            case ORDER_BY_USER_COUNT:
                comparator = userCountComparator;
                break;

            default:

                break;
        }

        quizListClone.sort(comparator)
        return quizListClone;
    }

    const handleLabelFilterChange = (event, newInputValue) => {


        if (newInputValue != null) {
            let quizId = newInputValue.id;
            let filterListClone = [...filterList];
            let filterFunc = (q => q.id === quizId);
            filterListClone.push(filterFunc)

            setFilterList(filterListClone)

        } else {
            setFilterList([]);
        }

    }




    const handleTagFilterChange = (event, newInputValue) => {

        if (newInputValue != null) {

            let filterListClone = [...filterList];
            let filterFunc = (q) => (q.tagList || []).some(tag => tag.label === newInputValue.label);
            filterListClone.push(filterFunc)

            setFilterList(filterListClone)

        } else {
            setFilterList([]);
        }

    }


    const generateTagList = () => {

        let tagList = filteredQuizList.flatMap(q => q.tagList).filter(tag => tag != null);

        let uniqueTagList = tagList.filter((value, index, self) =>
            index === self.findIndex((element) => (
                element.label === value.label
            ))
        )

        return uniqueTagList;

    }

    function roundToDecimalPlaces(number, decimalPlaces) {
        const factor = Math.pow(10, decimalPlaces);
        return Math.round(number * factor) / factor;
    }


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({

        backgroundColor: '#1565c0',
        color: 'white',

    }));




    const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({

        '&.MuiTableSortLabel-root': {
            color: 'white'
        }

    }));



    const handleSort = (property) => {

        const isAsc = orderBy === property && order === ORDER_ASC;
        setOrder(isAsc ? ORDER_DESC : ORDER_ASC);
        setOrderBy(property);

    };

    const handleDeleteQuizDeleteClicked = () => {

        let url = config.SERVER_URL + "/api/v1/quiz/admin/delete/" + selectedQuizForDelete.id;
        let responseHandler = (result) => {
            closeDeleteWarningModal();
            fetchQuizList();
        }

        executeFetch(url, "get", null, null, null, responseHandler);

    }


    const labelComparator = (a, b) => {

        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return getSortValue(labelA, labelB);

    };

    const getSortValue = (valueA, valueB) => {

        let value = 0;

        if (valueA < valueB) {
            value = -1;
        } else if (valueA > valueB) {
            value = 1;
        }

        if (order === ORDER_DESC) {
            value *= -1;
        }

        return value;

    }


    const dateComparator = (a, b) => {

        const valueA = a.modifyDate;
        const valueB = b.modifyDate;
        return getSortValue(valueA, valueB);
    };


    const userCountComparator = (a, b) => {

        const valueA = a.quizStats.userCount;
        const valueB = b.quizStats.userCount;
        return getSortValue(valueA, valueB);
    };


    useEffect(() => {

        fetchQuizList();
    }, []);

    const fetchQuizList = () => {
        var timeout;

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {

            let url = config.SERVER_URL + "/api/v1/quiz/admin/findAllWithStats";

            let responseHandler = (result) => {
                setLoading(false);
                setQuizList(result);

            }
            executeFetch(url, "get", null, null, null, responseHandler)


        }, 300);
    }


    let filteredQuizList = applyFiltersSort();

    let emptyRows = 7 - (Math.min(filteredQuizList.length, 10));


    return (



        <Box sx={{ height: 800, overflowY: 'scroll' }}>

            {loading && <Box sx={{ padding: '40px' }}><CircularProgress /></Box>}

            {!loading && <TableContainer>
                <Table size="small" aria-label="quiz table">
                    <TableHead>
                        <TableRow >

                            <StyledTableHeaderCell sx={{ width: 100 }}></StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 350 }}>
                                <StyledTableSortLabel direction={orderBy === 'label' ? order : 'asc'} onClick={() => handleSort('label')}>
                                    Label
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 100 }}>
                                <StyledTableSortLabel direction={orderBy === 'published' ? order : 'asc'} onClick={() => handleSort('published')}>
                                    Published
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 250 }}>Tags</StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 150 }}>
                                <StyledTableSortLabel direction={orderBy === 'modifyDate' ? order : 'asc'} onClick={() => handleSort('modifyDate')}>
                                    Modify Date
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell sx={{ width: 60 }}><StyledTableSortLabel direction={orderBy === 'users' ? order : 'desc'} onClick={() => handleSort('users')}>
                                Users
                            </StyledTableSortLabel></StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 100 }}>Avg Score</StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 120 }}>% Max Score</StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 130 }}></StyledTableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>

                            <TableCell></TableCell>

                            <TableCell>

                                <Autocomplete
                                    size="small"
                                    onChange={handleLabelFilterChange}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    options={quizList}
                                    renderInput={(params) => <TextField {...params} label="Label" />}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ fontSize: '12px' }}>{option.label}</li>
                                    )}
                                />

                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Autocomplete
                                    size="small"
                                    onChange={handleTagFilterChange}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}

                                    getOptionLabel={(option) => option.label}
                                    options={generateTagList()}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: '12px' }}>{option.label}</li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Tags" />}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>

                        </TableRow>


                        {filteredQuizList.map((quiz) => {

                            let tagList = quiz.tagList ? quiz.tagList : [];

                            let imageDescription = quiz.startImageMetadata && quiz.startImageMetadata.description ? quiz.startImageMetadata.description : '';

                            return <StyledTableRow key={quiz.id}>
                                <TableCell><img alt={imageDescription} style={{ borderRadius: '10%', width: 80 }} src={generateImageUrlFromCDN(quiz.startImageMetadata)} ></img></TableCell>
                                <TableCell>{quiz.label}</TableCell>
                                <TableCell>{quiz.published ? <Typography sx={{ color: 'green' }}>Yes</Typography> : <Typography sx={{ color: 'red' }}>No</Typography>}</TableCell>
                                <TableCell>{tagList.map(tag => tag.label + (tagList.indexOf(tag) < tagList.length - 1 ? ", " : ""))}</TableCell>
                                <TableCell>{quiz.modifyDate.substr(0, 10)} {quiz.modifyDate.substr(11, 5)}</TableCell>
                                <TableCell>{quiz.quizStats?.userCount}</TableCell>
                                <TableCell>%{roundToDecimalPlaces(quiz.quizStats?.totalCorrectAnswerCount * 100 / quiz.quizStats?.totalQuestionCount, 1)}</TableCell>
                                <TableCell>%{roundToDecimalPlaces(quiz.quizStats?.allCorrectCount * 100 / quiz.quizStats?.userCount, 1)}</TableCell>
                                <TableCell >
                                    <Box sx={{ display: 'flex', gap: '10px' }}>
                                        <Button size="small" variant="contained" onClick={() => handleLoadQuizClick(quiz)}>Load</Button>
                                        <Button size="small" variant="contained" color="error" onClick={() => handleDeleteQuizClick(quiz)}>Delete</Button>
                                    </Box>
                                </TableCell>


                            </StyledTableRow>
                        })}

                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 70 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer >
            }


            <Dialog open={deleteWarningPopupOpen} PaperProps={{
                sx: {
                    width: 350,
                    height: 250

                },
            }} onClose={closeDeleteWarningModal}>

                <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>

                    <Box sx={{ padding: '20px', height: '100%' }}>
                        <Typography variant="h5">Delete</Typography>

                        <Box sx={{ height: '50%', marginTop: '20px' }}>
                            {selectedQuizForDelete && <Typography variant="subtitle1" >Are you sure you want to delete the quiz '{selectedQuizForDelete.label}'?</Typography>}
                        </Box>
                        <Box sx={{ marginTop: '20px', display: 'flex', gap: '20px', width: '100%', justifyContent: 'right' }}>
                            <Button size="small" variant="outlined" onClick={closeDeleteWarningModal}>Cancel</Button>
                            <Button size="small" variant="contained" color="error" onClick={handleDeleteQuizDeleteClicked}>Delete</Button>
                        </Box>
                    </Box>

                </Box>
            </Dialog>



        </Box >


    )

}

export default QuizManager;

