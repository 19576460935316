import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MODE_PUBLISH } from "../Const";

const PublishDialog = ({ mode, quizLabel, open, onClose, onOk }) => {



    return (
        <Dialog PaperProps={{
            sx: {
                p: '10px'
            }
        }} open={open} onClose={onClose} >
            <DialogTitle>{mode === MODE_PUBLISH ? 'Publish Quiz ' + quizLabel : 'Unpublish Quiz ' + quizLabel}</DialogTitle>
            <DialogContent>

                Are you sure you want to {mode === MODE_PUBLISH ? 'publish' : 'unpublish'} the quiz?

            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={onOk} color="primary">Ok</Button>
                <Button variant={'outlined'} onClick={onClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog >
    );
};

export default PublishDialog;
