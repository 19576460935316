export const config = {
    "SERVER_URL": process.env.REACT_APP_API_URL,
    "APP_URL_HOST": process.env.REACT_APP_URL_HOST,
    "CDN_URL": process.env.REACT_APP_CDN_URL,
};

export const generateUUID = () => {
    let uniqueId = Date.now().toString(36) + '-' + Math.random().toString(36).substring(2);
    return uniqueId;

}

export const generateTimeStr = () => {
    let uniqueId = Date.now().toString(36);
    return uniqueId;

}

export const extractDateFromTimeStr = (timeStr) => {

    const timestampPart = timeStr;
    const timestamp = parseInt(timestampPart, 36);

    return new Date(timestamp);
};




export const LOCK_ASPECT_SHARE = { aspect: 1.905, minWidth: 1200 };
export const LOCK_ASPECT_START_QUIZ = { aspect: 1.3, minWidth: 600 };
export const LOCK_ASPECT_QUESTION = { aspect: 1.6, minWidth: 400 };
export const IMAGE_SELECTOR_IN_QUIZ_METADATA = 0;
export const IMAGE_SELECTOR_IN_QUIZ_QUESTION = 1;

export const MODE_PUBLISH = 0;
export const MODE_UNPUBLISH = 1;
